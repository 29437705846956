<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fire-resistant-struct-spec-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'fireResistantStructSpecInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'fireResistantStructSpecInfo', icon: 'info', label: '기본정보', component: () => import(`${'./fireResistantStructSpecInfo.vue'}`) },  // 내화구조 명세
        { name: 'fireResistantStructSpecPid', icon: 'tab', label: '도면', component: () => import(`${'./fireResistantStructSpecPid.vue'}`) }, // 내화구조도
        { name: 'fireResistantStructSpecDoc', icon: 'assignment', label: '문서', component: () => import(`${'./fireResistantStructSpecDoc.vue'}`) } // 내화구조 명세서
      ]
    },

  }
};
</script>